<template>
  <a-col :span="6" :offset="3" style="height: 480px">
    <p class="mb-0">Preview</p>
    <div
      class="phone-content"
      :style="`background-color: RGBA(${activeBgColor.red}, ${activeBgColor.green}, ${activeBgColor.blue}, ${activeBgColor.alpha})`"
    >
      <img src="resources/images/phone/panel.png" width="196px" style="position: relative; left: -18px; top: 0px">
      <div class="timeline">
        <div
          v-for="(item, index) in storiesCount"
          :key="index"
          class="item"
        >
          <div v-if="index === activeKey" class="active" :style="{width: `${(storyTime / storyLength) * 100}%`}" />
          <!--<div v-if="index === activeKey" class="active" :style="{width: `${(storyTime / storyLength) * 100}%`}" />-->
        </div>
      </div>
      <img src="resources/images/phone/close.png" width="16px" style="position: relative; left: 155px;">
      <div v-dragscroll.y="true" class="story_content">
        <div>
          <div
            class="title"
            :style="`color: RGBA(${activeTextColor.red}, ${activeTextColor.green}, ${activeTextColor.blue}, ${activeTextColor.alpha})`"
          >
            {{ story.title }}
          </div>
          <div
            class="description mt-2 mb-3"
            :style="`color: RGBA(${activeTextColor.red}, ${activeTextColor.green}, ${activeTextColor.blue}, ${activeTextColor.alpha})`"
          >
            {{ story.description }}
          </div>
          <div v-if="(story.media_path || previewSrc)">
<!--            <img v-if="(story.media_path || story.previewSrc)" :src="story.id ? story.media_path : story.previewSrc" width="165px">-->
            <img v-if="story.id" :src="story.media_path ? story.media_path : previewSrc" width="165px">
            <div v-else>
              <img v-if="previewSrc" :src="previewSrc" width="165px" />
              <p v-else>no media</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-col>
</template>

<script>
export default {
  name: 'phone',
  props: ['previewSrc', 'story', 'storiesCount', 'activeKey', 'activeBgColor', 'activeTextColor'],
  data() {
    return {
      interval: undefined,
      storyTime: 0,
      storyLength: 8000,
      backgroundColor: JSON.parse(this.story.bg_color),
      textColor: JSON.parse(this.story.text_color),
    }
  },
  // mounted() {
  //   this.interval = setInterval(() => {
  //     if (this.storyTime === this.storyLength) {
  //       this.storyTime = 0
  //       console.log(this.storiesCount)
  //     } else {
  //       this.storyTime += 50
  //     }
  //   }, 50)
  // },
  // beforeDestroy() {
  //   clearTimeout(this.interval)
  // },
}
</script>

<style lang="scss" scoped>
@import '@/components/mixins.scss';

@keyframes progress {
  to {width: 100%;}
}

.phone-content {
  width: 200px;
  height: 433px;
  padding: 0 18px;
  border-radius: 17px;
  border: 1.745px solid $gray-12;
  box-shadow: 0px 1.745px 24.43px rgba(170, 182, 185, 0.25);
  box-sizing: border-box;
  .story_content {
    height: 376px;
    //background: #eee;
    overflow-x: hidden;
    overflow-y: hidden;
    img {
      border: 0;
    }
  }
  * { cursor: grab; }
  .timeline {
    display: flex;
    height: 2px;
    width: 169px;
    .item {
      background-color: $gray-12;
      flex: auto;
      margin-right: 2px;
      .active {
        // width: 100%;
        height: 100%;
        background-color: $gray-11;
        animation: progress  8s infinite linear;
      }
    }
  }
  .title {
    font-weight: bold;
    font-size: 13.7536px;
    line-height: 16px;
  }
  .description {
    font-weight: normal;
    font-size: 8px;
    line-height: 11px;
    color: $gray-9;
  }
}
</style>
