<template>
  <div class="olimp-admin-form update">
    <a-form-model
      v-if="!loading"
      ref="updateForm"
      :model="form"
      :rules="rules"
    >
      <a-row>
        <p class="mt-n2 mb-4">
          Banner information
        </p>
        <a-col :span="6" class="pr-3">
          <a-form-model-item label="Input label">
            <a-input v-model="form.description" type="text" placeholder="Input Description" />
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pr-3">
          <a-form-model-item label="Update name" prop="name">
            <a-input v-model="form.name" type="text" placeholder="Input Name" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-row class="d-flex align-items-end">
            <a-col :span="21">
              <a-form-model-item label="Apps" class="mb-0" prop="applications">
                <a-select
                  v-model="application_ids"
                  mode="multiple"
                  placeholder="Please select"
                  :open="selectIsOpen"
                  :getPopupContainer="trigger => trigger.parentNode"
                  @change="changeApplications"
                  @focus="selectIsOpen = true"
                  @blur="selectIsOpen = false"
                >
                  <a-select-option v-for="(app, index) in apps" :key="app.application_id">
                    {{ app.name }}
                    <a-icon v-if="app.platform.toLowerCase() === 'android'" type="android" class="text-success" />
                    <a-icon v-if="app.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
                    <a-icon v-if="app.platform.toLowerCase() === 'web'" type="global" class="text-default" />
                    <!--
                    <div>
                      <a-input-number size="small" :min="1" :max="100000" :default-value="3" @change="changeMinVersion" />
                      <a-input-number size="small" :min="1" :max="100000" :default-value="3" @change="changeMaxVersion" />
                    </div>
                    -->
                    <div>
                      <a-input-group
                        class="mt-2"
                        compact
                      >
                        <a-tooltip :trigger="['focus']" title="Min version (e.g., 4.41)" placement="topLeft" overlay-class-name="numeric-input">
                          <a-input
                            style="width: 120px !important; height: 20px !important; font-size: 12px !important; text-align: center"
                            placeholder="Min Version"
                            :value="(app.min_app_version === '0.0')? '': app.min_app_version"
                            @click.stop="focusInput"
                            @change="changeVersion('min', index, $event)"
                            @keypress="isNumber($event)"
                          />
                        </a-tooltip>
                        <a-input
                          style="height: 20px !important; pointer-events: none; backgroundColor: #fff"
                          placeholder="~"
                          disabled
                        />
                        <a-tooltip :trigger="['focus']" title="Max version (e.g., 4.42)" placement="topLeft" overlay-class-name="numeric-input">
                          <a-input
                            style="width: 120px !important; height: 20px !important; font-size: 12px !important; text-align: center"
                            placeholder="Max Version"
                            :value="(app.max_app_version === '9.9.9.9')? '': app.max_app_version"
                            @click.stop="focusInput"
                            @change="changeVersion('max', index, $event)"
                            @keypress="isNumber($event)"
                          />
                        </a-tooltip>
                      </a-input-group>
                    </div>
                    <!-- <a-input-group compact @change="minMaxChange">
                      <a-input v-model="app.min_app_version" style="width: 80px !important; font-size: 14px !important; text-align: center" placeholder="Min Version" />
                      <a-input
                        style=" width: 30px; pointer-events: none; backgroundColor: #fff"
                        placeholder="~"
                        disabled
                      />
                      <a-input v-model="app.max_app_version" style="width: 80px !important; font-size: 14px !important; text-align: center;" placeholder="Max Version" />
                    </a-input-group> -->
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-button
            class="ml-1"
              :style="{
                fontSize: '10px',
                height: '40px',
              }"
              @click="selectIsOpen = !selectIsOpen"
            >
              <a-icon
                :type="selectIsOpen ? 'up' : 'down'"
              />
            </a-button>
          </a-row>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item required label="Language" prop="langs" style="clear:both;">
            <a-checkbox class="checkbox-item select-all" :indeterminate="indeterminateLang" :checked="checkAllLangs" @change="onCheckAllLangChange" style="left: 100px; margin-top: -12px;">
              Select all
            </a-checkbox>
            <a-checkbox-group class="checkbox-item grid-langs" v-model="checkedLangList" :options="updateLangs" @change="onLangChange"  />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item class="mr-3" label="Start date" prop="starts_at">
            <a-date-picker
              v-model="form.starts_at"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              type="date"
              placeholder="Pick a start date"
              style="width: 50%"
            />
          </a-form-model-item>
          <!-- <a-form-model-item label="End date" prop="ends_in">
            <a-date-picker
              v-model="form.ends_in"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              type="date"
              placeholder="Pick a date end"
              style="width: 223px;"
            />
          </a-form-model-item> -->
        </a-col>
      </a-row>
      <a-row class="divider mx-n4" />
      <a-row>
        <p class="my-3">
          Update information
        </p>
        <a-tabs
          v-model="activeKey"
          type="editable-card"
          @edit="onEditTabs"
          @change="onTabChange"
        >
          <a-tab-pane
            v-for="(story, index) in form.stories"
            :key="index"
            :tab="`${order[index]} screen`"
            :forceRender="true"
          >
            <a-row>
              <a-col :span="15" class="pr-4">
                <div>
                  <div class="font-size-12 text-gray-8 form-input-counter">{{ (30 - story.title.length) + ' characters left' }}</div>
                  <a-form-model-item
                    label="Title"
                    :key="`title${index}`"
                    :prop="`stories.${index}.title`"
                    :rules="[
                    { required: true, message: `Please input story title${story.sort}`, trigger: 'blur' },
                    { max: 30, message: 'Title length should be 30 maximum', trigger: 'blur' },
                  ]"
                  >
                    <a-input v-model="story.title" type="text" :maxLength=30 placeholder="Input title" />
                  </a-form-model-item>
                </div>
                <div>
                  <div class="font-size-12 text-gray-8 form-input-counter">{{ (135 - story.description.length) + ' characters left' }}</div>
                  <a-form-model-item
                    label="Description"
                    :key="`description${index}`"
                    :prop="`stories.${index}.description`"
                    :rules="[
                    { required: true, message: 'Please input story description', trigger: 'blur' },
                    { max: 135, message: 'Description length should be 135 maximum', trigger: 'blur' },
                  ]"
                  >

                    <a-input v-model="story.description" type="textarea" :maxLength=135 placeholder="Input description" />
                  </a-form-model-item>
                </div>
                <a-row>
                  <a-col :span="13" class="pr-1">
                    <a-form-model>
                      <a-upload
                        name="story-file"
                        action="#"
                        list-type="picture"
                        class="story-media-uploader"
                        accept=".png,.gif,.mp4"
                        :preview-file="previewFile"
                        :before-upload="beforeUpload"
                        :defaultFileList="story.media_path ? [{ uid: '1', name: story.media_path.substring(story.media_path.lastIndexOf('/') + 1), status: 'done', url: story.media_path, thumbUrl: story.media_path }] : []"
                        @change="fileChange"
                        :remove="fileRemove"
                      >
                        <div v-if="story.uploadVisible" style="margin-top: 8px !important;">
                          <a-button > <a-icon type="upload" /> Upload </a-button>
                          <div class="font-size-12 text-gray-8" style="margin-top: 10px !important;"> png, gif, mp4 (max. file size 50 mb)</div>
                        </div>
                        <!--                    <a-button> <a-icon type="upload" /> Upload </a-button>-->
                      </a-upload>
                    </a-form-model>
                  </a-col>
                  <a-col :span="3" class="pl-1">
                    <a-form-model-item
                      label="Story duration"
                      :key="`duration${index}`"
                      :prop="`stories.${index}.duration`"
                      :rules="[
                        { required: true, message: `Please input story duration ${story.sort}`, trigger: 'blur' },
                      ]"
                      >
                      <a-input-number v-model="story.duration" class="input-duration" :min="1" :max="300" :default-value="8" style="width: 100%" />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="8">
                    <div style="margin-top: 34px;" class="pl-2">sec</div>
                  </a-col>
                </a-row>
                <a-row class="mt-5">
                  <a-col :span="12">
                    <div class="color-operations">
                      <a-tooltip title="Copy color" placement="bottom">
                        <a href="javascript:" @click="bgColorCopy"><a-icon type="copy" /></a>
                      </a-tooltip>
                      <a-tooltip v-if="copiedColor" title="Paste color" placement="bottom">
                        <a href="javascript:" @click="bgColorPaste" class="ml-2"><a-icon type="form" /></a>
                      </a-tooltip>
                    </div>
                    <a-form-model-item label="Background-color">
                      <ColorPicker
                        :color="active_bg_color"
                        :onStartChange="color => onBgColorChange(color, 'start')"
                        :onChange="color => onBgColorChange(color, 'change')"
                        :onEndChange="color => onBgColorChange(color, 'end')"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="11" :offset="1">
                    <div class="color-operations" style="right: -8px;">
                      <a-tooltip title="Copy color" placement="bottom">
                        <a href="javascript:" @click="textColorCopy"><a-icon type="copy" /></a>
                      </a-tooltip>
                      <a-tooltip v-if="copiedColor" title="Paste color" placement="bottom">
                        <a href="javascript:" @click="textColorPaste" class="ml-2"><a-icon type="form" /></a>
                      </a-tooltip>
                    </div>
                    <a-form-model-item label="Text-color">
                      <ColorPicker
                        :color="active_text_color"
                        :onStartChange="color => onTextColorChange(color, 'start')"
                        :onChange="color => onTextColorChange(color, 'change')"
                        :onEndChange="color => onTextColorChange(color, 'end')"
                      />
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
        <!--Phone preview display-->
        <PhoneComponent
          style="position: absolute; right: 0px; top: 120px"
          :story="form.stories[activeKey]"
          :stories-count="form.stories.length"
          :active-key="activeKey"
          :active-bg-color="active_bg_color"
          :active-text-color="active_text_color"
          :preview-src="form.stories[activeKey].previewSrc"
          ref="phonePreviewComponent"
        />
        <hr>
          <a-row>
            <a-col class="text-left" :span="12">
              <span class="mr-2" v-if="updateObj.id">
                <a-popconfirm
                  title="Sure to delete?"
                  @confirm="deleteUpdate"
                >
                  <a href="javascript:;"><CustomButton>Delete</CustomButton></a>
                  <!-- <a href="javascript:;"><a-button>Delete</a-button> </a> -->
                </a-popconfirm>
              </span>
              <CustomButton @click="closeEditModal">
                Cancel
              </CustomButton>
            </a-col>
            <a-col class="text-right" :span="12">
              <a-button type="primary" @click="onSubmit">
                {{ updateObj.id ? 'Save' : 'Create' }}
              </a-button>
            </a-col>
          </a-row>
      </a-row>
    </a-form-model>
    <a-row v-if="loading" type="flex" justify="center">
      <a-col>
        <a-timeline
          v-if="loadingPercent !== 100"
          mode="alternate"
          :pending="loadingPercent !== 99 ? 'Loading...' : ''"
        >
          <a-timeline-item
            v-for="(item, index) in uploadingTimeline"
            :key="index"
            :color="index === 2 ? 'green' : 'blue' "
          >
            {{ item }}
          </a-timeline-item>
          <!-- <a-timeline-item color="green">Update created successfully</a-timeline-item> -->
        </a-timeline>
        <a-progress
          v-else
          type="circle"
          status="success"
          :stroke-color="{
            '0%': '#108ee9',
            '100%': '#87d068',
          }"
          :width="180"
          :percent="loadingPercent"
        />
        <div v-if="loadingPercent === 100" class="mt-4 text-center">
          {{form.id ? 'Update changed successfully!' : 'Update created successfully!'}}
        </div>
      </a-col>
    </a-row>
    <!-- <a-result
      status="success"
      :title="`Update successfully ${this.form.id ? 'updated' : 'created'}!`"
      sub-title="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
    >
      <template #extra>
        <a-button key="console" type="primary">
          Go Console
        </a-button>
        <a-button key="buy">
          Buy Again
        </a-button>
      </template>
    </a-result> -->
  </div>
</template>
<script>
import apiClient from '@/services/axios'
// import DEFAULT_UPDATE from './default_update'
import accessMix from '@/services/accessmix.js'
import PhoneComponent from './phone.vue'
import DEFAULT_STORY from './default_story'
import { ColorPicker } from 'vue-color-gradient-picker'
// import banmix from '@/services/bannermix'
import CustomButton from '@/components/custom/button/CustomButton'

export default {
  name: 'updateModal',
  props: ['updateObj', 'defObj', 'cpColor'],
  mixins: [accessMix],
  components: {
    ColorPicker, PhoneComponent, CustomButton,
  },
  data() {
    return {
      activeKey: 0,
      apps: undefined,
      selectIsOpen: false,
      form: {
        id: this?.updateObj?.id,
        name: this?.updateObj?.name,
        description: this?.updateObj.description,
        applications: this?.updateObj.applications,
        stories: this.updateObj.id !== undefined ? this.updateObj.stories : this.defObj.stories,
        langs: this?.updateObj?.langs?.map(value => value.id),
        starts_at: this?.updateObj['starts_at'],
        // ends_in: this?.updateObj['ends_in'],
        // langs: [],
      },
      loading: false,
      loadingPercent: 0,
      loadingStatus: '',
      order: ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth'],
      previewSrc: undefined,
      rules: {
        starts_at: [{ required: false, message: 'Please pick a start date', trigger: 'change' }],
        // ends_in: [{ required: false, message: 'Please pick an end date', trigger: 'change' }],
        name: [
          { required: true, message: 'Please input title for update', trigger: 'blur' },
        ],
        langs: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one localization',
            trigger: 'change',
          },
        ],
        applications: [
          {
            type: 'array',
            required: true,
            message: 'Please select at least one application',
            trigger: 'change',
          },
        ],
        // image_path: [{ required: true, message: 'Please input image link', trigger: 'blur' }],
      },
      storiesJson: undefined,
      uploadingTimeline: [],
      updateLangs: [],
      indeterminateLang: false,
      checkAllLangs: false,
      checkedLangList: this?.updateObj?.langs?.map(value => value.id),
      copiedColor: this.cpColor,
      // phoneRenderKey: 0,
      // checkedLangList: [],
    }
  },
  computed: {
    active_bg_color() {
      return JSON.parse(this.form.stories[this.activeKey].bg_color)
    },
    active_text_color() {
      return JSON.parse(this.form.stories[this.activeKey].text_color)
    },
    application_ids() {
      return this.form.applications.map(item => item.application_id)
    },
  },
  methods: {
    closeEditModal() {
      // console.log('closeEditModal')
      this.$emit('closeModalFunction')
    },
    onSubmit() {
      this.$refs.updateForm.validate(valid => {
        if (valid) {
          this.form.id ? this.patchUpdate() : this.createUpdate()
        } else {
          console.log('error update submit!!')
          return false
        }
      })
    },
    onLangChange(checkedLangList) {
      const updateLangKeys = this.updateLangs.map(record => record.value)
      this.indeterminateLang = !!checkedLangList.length && checkedLangList.length < updateLangKeys.length
      this.checkAllLangs = checkedLangList.length === updateLangKeys.length
      this.form.langs = checkedLangList
    },
    onCheckAllLangChange(e) {
      Object.assign(this, {
        checkedLangList: e.target.checked ? this.updateLangs.map(record => record.value) : [],
        indeterminateLang: false,
        checkAllLangs: e.target.checked,
      })
      this.form.langs = this.checkedLangList
    },
    async createUpdate() {
      const url = '/admin/updates'
      try {
        this.loading = true
        this.uploadingTimeline = this.uploadingTimeline = [...this.uploadingTimeline, 'Creating main content']
        const response = await apiClient.post(url, this.form)
        const newUpdate = response.data.data
        const stories = this.form.stories
        await new Promise(resolve => setTimeout(resolve, 500))
        this.uploadingTimeline = this.uploadingTimeline = [...this.uploadingTimeline, 'Uploading update`s stories']
        // const storiesLength = stories.length
        // const storiesCnt = 0
        this.loadingPercent += 66
        await Promise.all(stories.map(async story => {
          const storyResponse = await apiClient.post(`${url}/${newUpdate.id}/stories`, {
            title: story.title,
            description: story.description,
            bg_color: story.bg_color,
            text_color: story.text_color,
            sort: story.sort,
            duration: story.duration,
          })
          if (story.updateFile) {
            const formData = new FormData()
            formData.append('media', story.file)
            await apiClient.post(`${url}/stories/${storyResponse.data.data.id}/media`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            console.log()
          }
          await new Promise(resolve => setTimeout(resolve, 1000))
          this.uploadingTimeline = this.uploadingTimeline = [...this.uploadingTimeline, 'Story created successfully!']
          // this.loadingPercent += 33
          // storiesCnt += 1
          await new Promise(resolve => setTimeout(resolve, 500))
          // this.loadingPercent += 1
        }))
        this.uploadingTimeline = this.uploadingTimeline = [...this.uploadingTimeline, 'Update created successfully!']
        this.loadingPercent += 33
        this.loadingPercent += 1
        // this.$refs.updateForm.resetFields()
        this.$emit('refreshUpdates')
      } catch (error) {
        console.error(error)
        this.status = 'exception'
        this.$notification.error({
          message: 'Error while creating new update',
          description: error.message,
        })
      }
    },
    async patchUpdate() {
      this.loading = true
      this.uploadingTimeline = this.uploadingTimeline = [...this.uploadingTimeline, 'Patching main content']
      const prevUpdate = this.updateObj
      const nextUpdate = this.form
      // Patching update
      if (
        JSON.stringify(prevUpdate.applications) !== JSON.stringify(nextUpdate.applications) ||
        prevUpdate.name !== nextUpdate.name ||
        prevUpdate.description !== nextUpdate.description ||
        prevUpdate.langs !== nextUpdate.langs ||
        prevUpdate.starts_at !== nextUpdate.starts_at
        // ||
        // prevUpdate.ends_in !== nextUpdate.ends_in
      ) {
        const url = `/admin/updates/${nextUpdate.id}`
        try {
          console.log('patching update...')
          await apiClient.patch(url, {
            applications: nextUpdate.applications,
            name: nextUpdate.name,
            description: nextUpdate.description,
            langs: nextUpdate.langs,
            starts_at: nextUpdate.starts_at,
            // ends_in: nextUpdate.ends_in,
          })
        } catch (error) {
          console.error(error)
        }
      }
      await new Promise(resolve => setTimeout(resolve, 500))
      this.uploadingTimeline = this.uploadingTimeline = [...this.uploadingTimeline, 'Patching update`s stories']
      this.loadingPercent += 66
      // Updating stories records
      if (JSON.stringify(nextUpdate.stories) !== this.storiesJson) {
        const prevStories = JSON.parse(this.storiesJson)
        const nextStories = nextUpdate.stories

        await Promise.all(nextStories.map(async (story, index) => {
          if (!story.id) {
          // If story is a new one -> creating it
            const url = `/admin/updates/${nextUpdate.id}/stories`
            try {
              // apiClient.post(url, story)
              const storyResponse = await apiClient.post(url, story)
              story.id = storyResponse.data.data.id
              console.log('new story created')
            } catch (error) {
              console.error(error)
            }
          } else {
            // If story exists -> updating if it changes
            const prevStory = prevStories.filter(item => item.id === story.id)[0]
            const isEquals = (
              prevStory.title === story.title &&
              prevStory.description === story.description &&
              prevStory.bg_color === story.bg_color &&
              prevStory.text_color === story.text_color &&
              prevStory.media_path === story.media_path &&
              prevStory.duration === story.duration
            )
            if (!isEquals) {
              const url = `/admin/updates/stories/${story.id}`
              try {
                await apiClient.patch(url, {
                  title: story.title,
                  description: story.description,
                  bg_color: story.bg_color,
                  text_color: story.text_color,
                  duration: story.duration,
                })
                console.log(`story ${story.id} patched`)
              } catch (error) {
                console.error(error)
              }
            }
          }
          // delete media file
          if (story.deleteFile) {
            const url = '/admin/updates'
            await apiClient.delete(`${url}/stories/${story.id}/media`)
            console.log(`Media file deleted for story ${story.id}`)
          }
          // uploading media
          if (story.updateFile) {
            const formData = new FormData()
            const url = '/admin/updates'
            formData.append('media', story.file)
            await apiClient.post(`${url}/stories/${story.id}/media`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            console.log(`file uploaded for story ${story.id}`)
          }
        }))
        // search deleted items and remove them
        const prevIds = prevStories.map(item => item.id)
        const nextIds = nextStories.map(item => item.id)

        const deletedIds = prevIds.filter(num => !nextIds.includes(num))

        if (deletedIds.length !== 0) {
          try {
            await Promise.all(deletedIds.map(id => {
              const url = `/admin/updates/stories/${id}`
              apiClient.delete(url)
            }))
          } catch (error) {
            console.error(error)
          }
          console.log(`story(-ies) ${deletedIds} deleted`)
        }
      }
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.uploadingTimeline = this.uploadingTimeline = [...this.uploadingTimeline, 'Update changed successfully!']
      this.loadingPercent += 33
      await new Promise(resolve => setTimeout(resolve, 500))
      this.loadingPercent += 1
      this.$emit('refreshUpdates')
    },
    async deleteUpdate() {
      const url = '/admin/updates/' + this.updateObj.id
      try {
        const response = await apiClient.delete(url)
        const updatedList = response.data.data
        this.$notification.success({
          message: 'Update has been successfully deleted',
          description: '',
        })
        this.$emit('changeUpdates', updatedList)
      } catch (error) {
        console.error(error)
        this.$notification.error({
          message: 'Error while deleting update',
          description: error.message,
        })
      }
    },
    onEditTabs(targetKey, action) {
      this[`${action}Story`](targetKey)
    },
    onTabChange(targetKey) {
      console.log(targetKey)
      console.log(this.form.stories[targetKey].previewSrc)
    },
    addStory() {
      const stories = this.form.stories
      if (stories.length === 10) {
        this.$notification.error({
          message: 'Amount of stories per update has been increased',
          description: '',
        })
      } else {
        const newStory = { ...DEFAULT_STORY, sort: stories.length + 1 }
        stories.push(newStory)
        this.form.stories = stories
        this.activeKey = stories.length - 1
        console.log(this.form.stories, this.activeKey, 'Story added')
      }
    },
    removeStory(targetKey) {
      console.log(this.activeKey, targetKey, 'keys')
      this.form.stories = this.form.stories.filter(story => story.sort - 1 !== targetKey)
      this.form.stories = this.form.stories.map((story, index) => {
        return { ...story, sort: index + 1 }
      })
      if (this.activeKey >= targetKey && this.activeKey > 0) {
        this.activeKey -= 1
      }
    },
    async previewFile(file) {
      console.log('Your upload file:', file)
      const base64 = await this.toBase64(file)
      // this.form.stories[this.activeKey].file = file
      this.form.stories[this.activeKey].previewSrc = base64
      // BEGIN --- This shit needed to force rerender phoneComponent. Changing previewSrc with async base64 is not working
      this.form.stories[this.activeKey].description += ' '
      this.form.stories[this.activeKey].description = this.form.stories[this.activeKey].description.trim()
      // END -------------------------------------
      console.log('Your upload base64 file:', base64)
      // console.log('PhoneComponent preview:', this.$refs.phonePreviewComponent.previewSrc)
      // Your process logic. Here we just mock to the same file
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    fileChange(info) {
      this.form.stories[this.activeKey].file = info.file.originFileObj
      if (info.fileList.length > 0) {
        this.form.stories[this.activeKey].updateFile = true
        this.form.stories[this.activeKey].uploadVisible = false
      } else {
        this.form.stories[this.activeKey].updateFile = false
        this.form.stories[this.activeKey].uploadVisible = true
      }
      // console.log(this.form.stories[this.activeKey].file)
      console.log('file: ' + this.form.stories[this.activeKey].file)
      console.log('previewSrc: ' + this.form.stories[this.activeKey].previewSrc)
      console.log('to update: ' + this.form.stories[this.activeKey].updateFile)
      console.log('to delete: ' + this.form.stories[this.activeKey].deleteFile)
      console.log('upload btn visible: ' + this.form.stories[this.activeKey].uploadVisible)
    },
    fileRemove(info) {
      this.form.stories[this.activeKey].file = null
      this.form.stories[this.activeKey].previewSrc = null
      this.form.stories[this.activeKey].media_path = null
      this.form.stories[this.activeKey].deleteFile = true
      this.form.stories[this.activeKey].uploadVisible = true
      console.log('deleteFile = true')
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50
      if (!isLt2M) {
        this.$message.error('File size must be smaller than 50MB!')
        return false
      }
      return true
    },
    bgColorCopy() {
      this.copiedColor = this.form.stories[this.activeKey].bg_color
      this.$emit('copyColor', this.copiedColor)
      console.log(this.copiedColor)
    },
    textColorCopy() {
      this.copiedColor = this.form.stories[this.activeKey].text_color
      console.log(this.copiedColor)
    },
    bgColorPaste() {
      this.form.stories[this.activeKey].bg_color = this.copiedColor
      console.log('paste bg color', this.form.stories[this.activeKey].bg_color)
    },
    textColorPaste() {
      this.form.stories[this.activeKey].text_color = this.copiedColor
      console.log('paste text color', this.form.stories[this.activeKey].text_color)
    },
    onBgColorChange(attrs) {
      const { red, green, blue, alpha } = attrs
      this.form.stories[this.activeKey].bg_color = JSON.stringify({ red, green, blue, alpha })
    },
    onTextColorChange(attrs) {
      const { red, green, blue, alpha } = attrs
      this.form.stories[this.activeKey].text_color = JSON.stringify({ red, green, blue, alpha })
    },
    getApplications() {
      const url = '/admin/applications'
      apiClient.get(url).then((response) => {
        // console.log(response.data.data)
        const apps = response.data.data
        const selectedApps = this.updateObj.applications
        if (selectedApps.length > 0) {
          console.log('syncronizing apps...', this.updateObj.applications)
          for (let i = 0; i < apps.length; i++) {
            for (let k = 0; k < selectedApps.length; k++) {
              if (apps[i].application_id === selectedApps[k].application_id) {
                apps[i] = selectedApps[k]
                break
              }
            }
          }
          console.log('syncronizing ends', apps)
        }
        this.apps = apps
        // this.syncAppsValues()
      }).catch(error => { console.log(error) })
    },
    getLocalizations() {
      const url = '/admin/localizations'
      apiClient.get(url).then((response) => {
        this.updateLangs = response.data.data.map(function(row) {
          return { value: row.id, label: row.desc }
        })
      }).catch(error => { console.log(error) })
    },
    changeApplications(values) {
      this.form.applications = values.map(item =>
        this.apps.find(subItem =>
          subItem.application_id === item))
      console.log(this.apps, this.form.applications, this.a, values, 'h')
    },
    focusInput(event) {
      // console.log('event.target')
      // console.log(event.target)
      event.target.focus()
    },
    changeVersion(type, index, event) {
      this.apps[index][`${type}_app_version`] = event.target.value
      // this.apps[index][`${type}_app_version`] = event
      console.log(type, index, event.target.value)
      // console.log(type, index, event)
    },
    // syncAppsValues() {
    //   const loadedApps = this.form.applications
    //   this.apps.forEach(function(part, index, appArray) {
    //     const appItem = appArray[index]
    //     var fApp = loadedApps.find(objApp2 => {
    //       return objApp2.application_id === appItem.application_id
    //     })
    //     if (typeof fApp !== 'undefined') {
    //       appArray[index] = fApp
    //     }
    //     // console.log(fApp)
    //   })
    //   console.log('app synced')
    //   // console.log(loadedApps)
    // },
  },
  mounted() {
    console.log(this.updateObj.id)
    if (this.updateObj.id !== undefined) {
      this.storiesJson = JSON.stringify(this.updateObj.stories)
    } else {
      this.storiesJson = ''
      // this.$refs.updateForm.resetFields()
      this.form.stories = [{ ...DEFAULT_STORY }]
      this.activeKey = 0
    }
    // this.storiesJson = JSON.stringify(this.updateObj.stories)
    // console.log(this.storiesJson)
    // console.log(this.form.stories)
    this.getApplications()
    this.getLocalizations()
  },
}
</script>

<style src="vue-color-gradient-picker/dist/index.css" lang="css" />

<style lang="scss">
@import '@/components/mixins.scss';
  .color-preview-area {
    display: none;
  }
  .divider {
    height: 6px !important;
    background: $gray-11;
  }
  .update {
    .grid-langs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: .7rem;
    }
    .color-operations {
      position: absolute;
      right: 18px;
      z-index: 99999;
    }
    .story-media-uploader {
      .ant-upload {
        width: 100%;
        height: 60px;
      }
    }
    .input-duration { width: 100% !important; max-width: none !important; }
    .ant-upload-select-picture-card { width: 160px; }
    .ant-upload-list-item-name {
      color: $primary;
    }
    .ant-upload-list-item-card-actions {
      .anticon-delete {
        color: $danger;
      }
    }
    .ant-input  {
      width: 100% !important;
    }
    .ant-form-item-label {
      padding: 0px !important;
    }
    .ant-select-selection {
      height: auto !important;
      min-height: 40px;
    }
    .ant-select-selection__choice__content {
      div {
        display: none;
      }
  }
    .ant-tabs-extra-content {
      //:hover, :active {
      //  color: $white !important;
      //}
      .ant-tabs-new-tab {
        height: 28px; width: 28px; margin-top: 0.5rem;
        // background: $blue; color: $white;
        //:hover, :active {
        //  color: $white;
        //}
      }
    }
    textarea.ant-input {
      height: 120px !important;
    }
    .ant-upload {
      button {
        padding: 0 16px !important;
      }
      .anticon {
        margin-right: 4px !important;
      }
    }
    .form-input-counter {
      position: absolute;
      margin-top:6px;
      right:24px;
    }
  }
</style>
